import { Button, Form, Input, Select, Table, Space, Tooltip, Divider } from 'antd';
import { EyeOutlined, SwitcherOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
import { DefaultPaginationConfig, DEFAULT_PAGE_SIZE } from '../../constants/pagination';
import { USUARIOS_ENDPOINT, getAllUsuarios } from '../../services/api/usuarios';
import UsuarioItemPage from './usuario-item'
import UsuarioChangeStatus from './usuario-change-status'

const { Column } = Table;

const itemLayout = {
    labelCol: { span: 24 },
};

const initialFilters = {
    username: '',
    nombre: '',
    activo: true,
    sortField: 'username',
    sortOrder: 'ascend',
    pageNumber: 1,
    pageSize: DEFAULT_PAGE_SIZE,
};

const UsuarioList = () => {
    const [filters, setFilters] = useState({ ...initialFilters });
    const [selectedFilters, setSelectedFilters] = useState({ ...initialFilters });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(0);
    const [selectedIdStatus, setSelectedIdStatus] = useState(0);
    const [isModalOpenStatus, setIsModalOpenStatus] = useState(false);
    

    const {
        data: usuariosData,
        error,
        mutate,
    } = useSWR([USUARIOS_ENDPOINT, filters], ([_, filters]) => getAllUsuarios(filters));    
    const isLoading = !error && !usuariosData;
    const [form] = Form.useForm();

    const handleTableChange = (pagination, _, sorter) => {
        const params = {
            ...filters,
            pageNumber: Number(pagination.current),
            pageSize: Number(pagination.pageSize),
            sortField: sorter.columnKey,
            sortOrder: sorter.order,
        };

        setSelectedFilters({ ...params });
        setFilters({ ...params });
    };

    const getActionColumn = (usuario) => {
        return (
            <Space size={0}>             
                <Divider type='vertical' />
                <Tooltip title='Visualizar'>
                    <EyeOutlined onClick={() => handleEditClick(usuario.id)} />
                </Tooltip>
                <Divider type='vertical' />
                <Tooltip title='Cambiar estado'>
                    <SwitcherOutlined  onClick={() => handleChangeState(usuario.id)} />
                </Tooltip>
            </Space>
        );
    };

    const handleEditClick = (id) => {
        setSelectedId(id);
        setIsModalOpen(true);
    };

    const handleShowSizeChange = (current, pageSize) => {
        setSelectedFilters({
            ...filters,
            pageSize: pageSize,
        });
    };

    const handleFinish = async (values) => {
        const params = {
            ...selectedFilters,
            username: values.username ?? null,
            nombre: values.nombre ?? null,
            activo: values.activo === "activo" ? true : false,
            pageNumber: 1,
        };        
        setFilters(params);
    };

    const handleChangeState = (id) => {
        setSelectedIdStatus(id);
        setIsModalOpenStatus(true);
    };

    const handleCancelModal = () => {
        setIsModalOpen(false);
    };
        
    const handleOkModalStatus = () => {
        mutate();
        setSelectedId(0);
        setIsModalOpenStatus(false);
    };

    const handleCancelModalStatus = () => {
        setIsModalOpenStatus(false);
    };
      

    return (
        <>
                    <Form form={form} onFinish={handleFinish} layout='vertical'>
                            <div className="row my-3 mx-2 py-3 text-white">
                                <div className="col-md-4 py-2"> 
                                <label className="form-label fs-base">Username</label>
                                    <Form.Item name='username' rules={[{ whitespace: true }]}>
                                        <Input  className="form-label fs-base"/>
                                    </Form.Item>
                                </div>
                                <div className="col-md-4 py-2">
                                <label className="form-label fs-base">Nombre</label>
                                    <Form.Item name='nombre' className="form-label fs-base" rules={[{ whitespace: true }]}>
                                        <Input />
                                    </Form.Item>
                                </div>
                                <div className="col-md-4 py-2">
                                    <label className="form-label fs-base">Estado Usuario</label>
                                    <Form.Item name='activo' {...itemLayout}>
                                        <Select className='w-100'>
                                            <Select.Option key="1" value="activo">Activo</Select.Option>
                                            <Select.Option key="0" value="inactivo">Inactivo</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>                
                                <div className='d-flex justify-content-end'>
                                    <div className="col-md-4">
                                        <Form.Item>                        
                                                <Button type='primary' htmlType='submit' className="btn btn-primario shadow-primary btn-lg w-100 rounded-pill text-uppercase">
                                                    Filtrar
                                                </Button>                            
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                        </Form>
                        <div className="row bg-box rounded-4 my-3 mx-2 p-3 mb-5">
                            <div className="table-dark p-3 rounded-4">
                                <Table
                                    rowKey={(usuario) => usuario.id}
                                    dataSource={usuariosData?.data.items}
                                    pagination={{
                                        ...DefaultPaginationConfig,
                                        current: filters.pageNumber,
                                        total: usuariosData?.data.totalCount,
                                        onShowSizeChange: handleShowSizeChange,
                                    }}                                    
                                    loading={isLoading}                                    
                                    onChange={handleTableChange}                               
                                >
                                    <Column title='Id' dataIndex='id' key='id' sorter />
                                    <Column title='Username' dataIndex='username' key='username' sorter />
                                    <Column title='Nombre' dataIndex='nombre' key='nombre' sorter />
                                    <Column title='Apellido Paterno' dataIndex='apellidoPaterno' key='apellidoPaterno' sorter />
                                    <Column title='Apellido Materno' dataIndex='apellidoMaterno' key='apellidoMaterno' sorter />
                                    <Column title='Estado' dataIndex='activo' key='activo' sorter render={activo => (activo ? 'Activo' : 'Inactivo')} />
                                    <Column title='Acción' render={(usuario) => getActionColumn(usuario)} />
                                </Table>                                
                            </div>
                        </div>                 
                
                <div className='d-flex justify-content-end'>
                    <div className="col-md-4"></div>
                 </div>
                    <div className='d-flex justify-content-end'>
                    <div className="col-md-4"> .</div>
                </div>
                {isModalOpen && (
                    <UsuarioItemPage
                    id={selectedId}
                    isModalOpen={isModalOpen}                 
                    onCancel={handleCancelModal}
                    />
                )}
                {isModalOpenStatus && (
                    <UsuarioChangeStatus
                    id={selectedIdStatus}
                    isModalOpen={isModalOpenStatus}
                    onOk={handleOkModalStatus}
                    onCancel={handleCancelModalStatus}
                    />
                )}                
        </>
    );
};

export default UsuarioList;
