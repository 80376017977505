import { Form, Input, message, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect } from 'react';
import useSWRImmutable from 'swr/immutable';
import { GENERIC_ERROR_MESSAGE, GENERIC_SUCCESS_MESSAGE } from '../../constants/feedback';
import {
    ADMIN_NOTIFICACIONES_ENDPOINT,
    getAdminNotificacionById,
    createAdminNotificacion,
    updateAdminNotificacion,
} from '../../services/api/admin-notificacion';

const itemLayout = {
    labelCol: { span: 24 },
};

const AdminNotificacionItem = (props) => {
    const { id, isModalOpen, onOk, onCancel } = props;

    const [form] = Form.useForm();

    const { data: adminNotificacionData } = useSWRImmutable(
        id > 0 ? [ADMIN_NOTIFICACIONES_ENDPOINT, id] : null,
        ([_, id]) => getAdminNotificacionById(id),
        {
            revalidateOnMount: true,
        }
    );

    useEffect(() => {
        if (adminNotificacionData) {
            const values = {
                titulo: adminNotificacionData.data.titulo,
                mensaje: adminNotificacionData.data.mensaje,
                idEstado: adminNotificacionData.data.idEstado,
            };

            form.setFieldsValue(values);
        }
    }, [adminNotificacionData, form]);

    const handleOk = () => {
        form.submit();
    };

    const handleCancel = () => {
        form.resetFields();
        onCancel();
    };

    const handleFinishClick = async (values) => {
        try {
            const params = {
                id: id ?? 0,
                titulo: values.titulo,
                mensaje: values.mensaje,
                idEstado: values.idEstado,
            };

            if (id > 0) {
                await updateAdminNotificacion(params);
            } else {
                await createAdminNotificacion(params);
            }

            form.resetFields();
            onOk();

            message.success(GENERIC_SUCCESS_MESSAGE);
        } catch (error) {
            message.error(GENERIC_ERROR_MESSAGE);
        }
    };

    return (
        <>
            <Modal title='Notificación' open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Form form={form} onFinish={handleFinishClick}>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Form.Item
                                name='titulo'
                                label='Título'
                                {...itemLayout}
                                rules={[{ required: true, whitespace: true }]}
                            >
                                <Input maxLength={100} allowClear />
                            </Form.Item>
                        </div>

                        <div className='col-md-12'>
                            <Form.Item
                                name='mensaje'
                                label='Mensaje'
                                {...itemLayout}
                                rules={[{ required: true, whitespace: true }]}
                            >
                                <TextArea maxLength={500} showCount allowClear />
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

export default AdminNotificacionItem;
