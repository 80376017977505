import instance from '../../lib/axios';

export const ADMIN_NOTIFICACIONES_ENDPOINT = '/admin-notificaciones';

export const getAllAdminNotificaciones = async (params) => {
    const response = await instance.get(ADMIN_NOTIFICACIONES_ENDPOINT, { params });
    return response;
};

export const getAdminNotificacionById = async (id) => {
    const response = await instance.get(`${ADMIN_NOTIFICACIONES_ENDPOINT}/${id}`);
    return response;
};

export const createAdminNotificacion = async (params) => {
    const response = await instance.post(ADMIN_NOTIFICACIONES_ENDPOINT, params);
    return response;
};

export const updateAdminNotificacion = async (params) => {
    const response = await instance.put(ADMIN_NOTIFICACIONES_ENDPOINT, params);
    return response;
};

export const deleteAdminNotificacion = async (id) => {
    const response = await instance.delete(`${ADMIN_NOTIFICACIONES_ENDPOINT}/${id}`);
    return response;
};

export const sendAdminNotificacion = async (id) => {
    const response = await instance.post(`${ADMIN_NOTIFICACIONES_ENDPOINT}/${id}/notification`);
    return response;
};
