import { Button, Form, Select, Table, Space, Tooltip, Divider, Popconfirm, message, DatePicker, Input } from 'antd';
import { EditTwoTone, DeleteTwoTone, SendOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';
import { DefaultPaginationConfig, DEFAULT_PAGE_SIZE } from '../../constants/pagination';
import { ESTADO_ADMIN_NOTIFICACION_ENDPOINT, getAllEstadosAdminNotificacion } from '../../services/api/enumeracion';
import {
    deleteAdminNotificacion,
    ADMIN_NOTIFICACIONES_ENDPOINT,
    getAllAdminNotificaciones,
    sendAdminNotificacion,
} from '../../services/api/admin-notificacion';
import { DELETE_CONFIRMATION_MESSAGE, GENERIC_ERROR_MESSAGE, GENERIC_SUCCESS_MESSAGE } from '../../constants/feedback';
import AdminNotificacionItem from './admin-notificacion-item';
import { ESTADO_ADMIN_NOTIFICACION_ENVIADA } from '../../constants/estado-admin-notificacion';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Column } = Table;

const itemLayout = {
    labelCol: { span: 24 },
};

const initialFilters = {
    titulo: '',
    idEstado: '',
    fechaCreacionDesde: '',
    fechaCreacionHasta: '',
    sortField: 'fechaCreacion',
    sortOrder: 'ascend',
    pageNumber: 1,
    pageSize: DEFAULT_PAGE_SIZE,
};

const AdminNotificacionList = () => {
    const [filters, setFilters] = useState({ ...initialFilters });
    const [selectedFilters, setSelectedFilters] = useState({ ...initialFilters });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(0);

    const { data: estadosAdminNotificacionData } = useSWRImmutable(ESTADO_ADMIN_NOTIFICACION_ENDPOINT, () =>
        getAllEstadosAdminNotificacion()
    );

    const {
        data: adminNotificacionesData,
        isLoading,
        mutate,
    } = useSWR([ADMIN_NOTIFICACIONES_ENDPOINT, filters], ([_, filters]) => getAllAdminNotificaciones(filters));

    const [form] = Form.useForm();

    const handleTableChange = (pagination, _, sorter) => {
        const params = {
            ...filters,
            pageNumber: Number(pagination.current),
            pageSize: Number(pagination.pageSize),
            sortField: sorter.columnKey,
            sortOrder: sorter.order,
        };

        setSelectedFilters({ ...params });
        setFilters({ ...params });
    };

    const getActionColumn = (adminNotificacion) => {
        return (
            <Space size={0}>
                {adminNotificacion.idEstado !== ESTADO_ADMIN_NOTIFICACION_ENVIADA && (
                    <>
                        <Tooltip title='Enviar'>
                            <SendOutlined onClick={() => handleSendClick(adminNotificacion.id)} />
                        </Tooltip>

                        <Divider type='vertical' />

                        <Tooltip title='Editar'>
                            <EditTwoTone onClick={() => handleEditClick(adminNotificacion.id)} />
                        </Tooltip>

                        <Divider type='vertical' />
                    </>
                )}

                <Popconfirm
                    title={DELETE_CONFIRMATION_MESSAGE}
                    onConfirm={() => handleConfirmDelete(adminNotificacion.id)}
                >
                    <Tooltip title='Eliminar' className='cursor-pointer'>
                        <DeleteTwoTone />
                    </Tooltip>
                </Popconfirm>
            </Space>
        );
    };

    const handleShowSizeChange = (current, pageSize) => {
        setSelectedFilters({
            ...filters,
            pageSize: pageSize,
        });
    };

    const handleConfirmDelete = async (id) => {
        try {
            await deleteAdminNotificacion(id);

            message.success(GENERIC_SUCCESS_MESSAGE);
            mutate();
        } catch (error) {
            message.error(GENERIC_ERROR_MESSAGE);
        }
    };

    const handleSendClick = async (id) => {
        try {
            await sendAdminNotificacion(id);

            message.success(GENERIC_SUCCESS_MESSAGE);
            mutate();
        } catch (error) {
            message.error(GENERIC_ERROR_MESSAGE);
        }
    };

    const handleEditClick = (id) => {
        setSelectedId(id);
        setIsModalOpen(true);
    };

    const handleNewClick = () => {
        setIsModalOpen(true);
    };

    const handleOkModal = () => {
        mutate();
        setSelectedId(0);
        setIsModalOpen(false);
    };

    const handleCancelModal = () => {
        setIsModalOpen(false);
    };

    const handleFinish = async (values) => {
        const fechaCreacionRange = values['fechaCreacion'];

        const params = {
            ...selectedFilters,
            titulo: values.titulo ?? null,
            idEstado: values.idEstado ?? null,
            fechaCreacionDesde: fechaCreacionRange ? fechaCreacionRange[0].toISOString() : null,
            fechaCreacionHasta: fechaCreacionRange ? fechaCreacionRange[1].toISOString() : null,
            pageNumber: 1,
        };

        setFilters(params);
    };

    return (
        <>
            <div className='row my-3 mx-2 py-3 text-white'>
                <div className='col-lg-12'>
                    <Form form={form} onFinish={handleFinish}>
                        <div className='row pl-2 pr-2 text-white'>
                            <div className='col-md-4 pt-2 pb-3'>
                                <label className='form-label fs-base'>Título del mensaje</label>
                                <Form.Item name='titulo' {...itemLayout} rules={[{ whitespace: true }]}>
                                    <Input maxLength={100} allowClear />
                                </Form.Item>
                            </div>

                            <div className='col-md-4  pt-2 pb-3'>
                                <label className='form-label fs-base'>Estado</label>
                                <Form.Item name='idEstado' {...itemLayout}>
                                    <Select className='w-100' allowClear>
                                        {estadosAdminNotificacionData?.data.map((rubro) => (
                                            <Option key={rubro.id} value={rubro.id}>
                                                {rubro.nombre}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className='col-md-4 pt-2 pb-3'>
                                <label className='form-label fs-base'>Fecha creación</label>
                                <Form.Item name='fechaCreacion' {...itemLayout}>
                                    <RangePicker className='w-100' />
                                </Form.Item>
                            </div>

                            <div className='d-flex justify-content-end'>
                                <div className='col-md-4'>
                                    <Form.Item>
                                        <Button
                                            type='primary'
                                            htmlType='submit'
                                            className='btn btn-primario shadow-primary btn-lg w-100 rounded-pill text-uppercase'
                                        >
                                            Filtrar
                                        </Button>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>

            <div className='row bg-box rounded-4 my-3 mx-2 p-3 mb-5'>
                <div className='table-dark p-3 rounded-4'>
                    <Table
                        rowKey={(adminNotiticacion) => adminNotiticacion.id}
                        dataSource={adminNotificacionesData?.data.items}
                        pagination={{
                            ...DefaultPaginationConfig,
                            current: filters.pageNumber,
                            total: adminNotificacionesData?.data.totalCount,
                            onShowSizeChange: handleShowSizeChange,
                        }}
                        loading={isLoading}
                        onChange={handleTableChange}
                    >
                        <Column title='Id' dataIndex='id' key='id' sorter />
                        <Column title='Título del mensaje' dataIndex='titulo' key='titulo' sorter />
                        <Column title='Estado' dataIndex='nombreEstado' key='idEstado' sorter />
                        <Column
                            title='Fecha'
                            dataIndex='fechaCreacion'
                            key='fechaCreacion'
                            sorter
                            render={(fechaCreacion) => new Date(fechaCreacion).toLocaleDateString()}
                        />

                        <Column title='Acción' render={(adminNotiticacion) => getActionColumn(adminNotiticacion)} />
                    </Table>
                </div>
            </div>

            <div className='d-flex justify-content-end mb-4'>
                <div className='col-md-4'>
                    <Button
                        type='primary'
                        className='btn btn-primario shadow-primary btn-lg w-100 rounded-pill text-uppercase'
                        onClick={handleNewClick}
                    >
                        Nueva notificación
                    </Button>
                </div>
            </div>

            {isModalOpen && (
                <AdminNotificacionItem
                    id={selectedId}
                    isModalOpen={isModalOpen}
                    onOk={handleOkModal}
                    onCancel={handleCancelModal}
                />
            )}
        </>
    );
};

export default AdminNotificacionList;
