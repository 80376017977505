import React from 'react';
import logoMeteteSvg from '../../assets/image/Logo_metete.svg';
import imgDashboardPng from '../../assets/image/img-dashboard.png';

const Policy = () => {
    return (
        <>
            <div
                className='col-lg-12 position-absolute  w-100   bg-repeat-0 bg-size-cover top-3'
                style={{ backgroundImage: `url(${imgDashboardPng})` }}
            >
                <div className='container'>
                    <div className='row bg-box rounded-4 my-3 mx-2 p-3 mb-5'>
                        <div className='card'>
                            <h4 className='card-header text-center text-color-blue py-3'>Política de Privacidad</h4>

                            <div className='col-md-4 mx-auto text-center py-4'>
                                <img className='img-fluid w-50' src={logoMeteteSvg} alt='Metete' />
                            </div>

                            <div className='card-body'>
                                <h5 className='card-title'>
                                    Agustín Farías, como propietario y responsable del tratamiento de los datos
                                    personales recabados a través de la aplicación METETE, informa que dicho tratamiento
                                    se realiza en estricto cumplimiento de lo dispuesto en el artículo 19 N°4 de la
                                    Constitución Política de la República de Chile, así como en la Ley N°19.628 sobre
                                    Protección de la Vida Privada y sus modificaciones posteriores. En METETE, nos
                                    adherimos a los principios de protección de datos personales, garantizando su
                                    seguridad, confidencialidad y uso adecuado. A continuación, se detallan las reglas y
                                    prácticas que rigen el tratamiento de sus datos personales en nuestra plataforma:
                                </h5>

                                <div className='card-text'>
                                    <ul className='list-group list-group-flush'>
                                        <li className='list-group-item'>
                                            <strong>1. Información recopilada</strong>
                                        </li>

                                        <li className='list-group-item'>
                                            <p>
                                                Los datos personales recabados se circunscriben a nombre, apellido
                                                paterno, correo, apellido materno, fecha de nacimiento, género,
                                                nacionalidad, dirección. Los datos recopilados son de los usuarios que
                                                se registren en la aplicación METETE.
                                            </p>
                                        </li>

                                        <li className='list-group-item'>
                                            <strong>2. Finalidad</strong>
                                        </li>
                                        <li className='list-group-item'>
                                            <p>
                                                Los datos personales recopilados serán utilizados, únicamente, con la
                                                finalidad de contar un registro de los usuarios de la aplicación y
                                                brindar acceso de manera personalizada y segura, además para generar
                                                comunicación con el usuario para los eventos deportivos, para análisis
                                                estadísticos y rendimiento y para la personalización de la experiencia
                                                del usuario.
                                            </p>
                                        </li>

                                        <li className='list-group-item'>
                                            <strong>3. Legitimación</strong>
                                        </li>
                                        <li className='list-group-item'>
                                            <p>
                                                El propietario de la aplicación METETE es el responsable del tratamiento
                                                de los datos personales que se recaban a través de su aplicación móvil
                                                de eventos deportivos. De acuerdo con el artículo 4 de la Ley N° 19.628
                                                sobre Protección de la Vida Privada, el tratamiento de los datos
                                                personales por parte del propietario de la aplicación METETE, se realiza
                                                bajo el consentimiento expreso de los usuarios, quienes autorizan el uso
                                                de sus datos personales al registrarse y utilizar la aplicación. Este
                                                consentimiento se alinea con la finalidad de proporcionar una
                                                experiencia personalizada y segura en la aplicación, así como para la
                                                organización y gestión de eventos deportivos, comunicaciones
                                                relacionadas con dichos eventos, análisis estadísticos y personalización
                                                de la experiencia del usuario. La recopilación y tratamiento de datos se
                                                llevan a cabo respetando los derechos de acceso, rectificación,
                                                cancelación y oposición que la ley confiere a los titulares de los
                                                datos.
                                            </p>
                                        </li>

                                        <li className='list-group-item'>
                                            <strong>4. Confidencialidad de los datos personales</strong>
                                        </li>
                                        <li className='list-group-item'>
                                            <p>
                                                El propietario de la aplicación METETE se compromete a mantener la
                                                debida confidencialidad de los datos personales recogidos a través de la
                                                aplicación METETE. Estos datos no serán comunicados a terceros, salvo en
                                                situaciones donde exista un mandato legal o una orden emitida por los
                                                Tribunales de Justicia que requiera su divulgación. Esta excepción se
                                                aplica estrictamente en casos donde la ley así lo exija, asegurando
                                                siempre el cumplimiento de nuestras obligaciones legales y la protección
                                                de los derechos de nuestros usuarios.
                                            </p>
                                        </li>

                                        <li className='list-group-item'>
                                            <strong>5. Medidas de Seguridad</strong>
                                        </li>
                                        <li className='list-group-item'>
                                            <p>
                                                Agustín Farías, en su calidad de propietario de la aplicación METETE, ha
                                                adoptado los niveles de seguridad requeridos legalmente para la
                                                protección de datos personales, prestando especial atención a la
                                                naturaleza sensible de la información recopilada. Para garantizar la
                                                seguridad y confidencialidad de los datos, METETE ha implementado una
                                                variedad de medidas, tanto técnicas como organizativas, destinadas a
                                                prevenir la pérdida, el mal uso, la alteración y el acceso no autorizado
                                                a los datos personales proporcionados por los usuarios. Estas medidas
                                                están diseñadas para asegurar la integridad y privacidad de la
                                                información en todo momento.
                                            </p>
                                        </li>

                                        <li className='list-group-item'>
                                            <strong>6. Almacenamiento de los datos personales</strong>
                                        </li>
                                        <li className='list-group-item'>
                                            <p>
                                                La información registrada en la plataforma METETE será almacenada en los
                                                servidores del proveedor Digital Ocean. Agustín Farías, como propietario
                                                de la aplicación METETE, se reserva la posibilidad de delegar o
                                                transferir ciertas responsabilidades a proveedores de servicios de red.
                                                Estos proveedores han sido seleccionados por cumplir con las medidas
                                                técnicas adecuadas y necesarias para adherirse a las normativas chilenas
                                                vigentes en materia de protección de datos personales. Este enfoque
                                                asegura que la seguridad y confidencialidad de los datos personales se
                                                mantengan en línea con los estándares legales requeridos.
                                            </p>
                                        </li>

                                        <li className='list-group-item'>
                                            <strong>7. Conservación de los datos personales</strong>
                                        </li>
                                        <li className='list-group-item'>
                                            <p>
                                                En virtud a los principios de finalidad y proporcionalidad, los datos
                                                serán conservados únicamente durante el tiempo necesario para cumplir
                                                con la finalidad para la que fueron recabados, luego del cual serán
                                                cancelados. En este proceso de eliminación de los datos, se adoptarán
                                                las medidas técnicas necesarias para impedir que se pueda reversar la
                                                operación y recuperar posteriormente los datos eliminados.
                                            </p>
                                        </li>

                                        <li className='list-group-item'>
                                            <strong>8. Cambios a la política de privacidad</strong>
                                        </li>
                                        <li className='list-group-item'>
                                            <p>
                                                Esta política de privacidad podrá ser modificada, y los usuarios serán
                                                informados a través a la dirección de correo electrónica indicada al
                                                momento de registrarse en la plataforma. Los cambios en la forma en que
                                                su información es administrada serán notificados oportunamente para que
                                                usted pueda tomar una decisión informada respecto si acepta o no el uso
                                                de su información.
                                            </p>
                                        </li>

                                        <li className='list-group-item'>
                                            <strong>9. Ejercicio de los derechos otorgados por la Ley N°19.628</strong>
                                        </li>
                                        <li className='list-group-item'>
                                            <p>
                                                Los titulares de los datos personales recopilados podrán, en todo
                                                momento, ejercer los derechos otorgados por la Ley N°19.628. Esto
                                                comprende, en especial, la posibilidad de:
                                            </p>
                                            <p>
                                                a. Solicitar información sobre los datos relativos a su persona, su
                                                procedencia y destinatario, el propósito del almacenamiento y la
                                                individualización de las personas a los cuales sus datos son
                                                transmitidos regularmente.
                                            </p>
                                            <p>
                                                b. Solicitar se modifiquen sus datos personales cuando ellos no sean
                                                correctos o no estén actualizados, si fuere procedente.
                                            </p>
                                            <p>
                                                c. Solicitar la eliminación o bloqueo de los datos entregados cuando así
                                                corresponda, en tanto fuere procedente.
                                            </p>
                                            <p>
                                                Para ejercer sus derechos de acceso, rectificación y eliminación, los
                                                titulares de datos personales deberán efectuar una solicitud por vía
                                                electrónica, a través de la dirección de correo electrónico
                                                metete.contacto@gmail.com
                                            </p>
                                        </li>

                                        <li className='list-group-item'>
                                            <strong>
                                                Proceso para la Eliminación de la Cuenta Creada en la Aplicación METETE
                                            </strong>
                                        </li>
                                        <li className='list-group-item'>
                                            <p>
                                                El propietario de la aplicación METETE respeta tu derecho a la
                                                privacidad y comprende la importancia de mantener el control sobre tus
                                                datos personales. Si deseas que eliminemos tu información de nuestros
                                                registros, puedes solicitarlo enviando un correo electrónico a
                                                metete.contacto@gmail.com. Tras recibir tu solicitud, procederemos a
                                                eliminar tus datos personales de nuestras bases de datos de forma segura
                                                y de acuerdo con las leyes aplicables. Ten en cuenta que este proceso
                                                puede requerir un tiempo razonable para su completa ejecución. Además,
                                                es posible que retengamos cierta información en la medida necesaria para
                                                cumplir con obligaciones legales, resolver disputas y hacer cumplir
                                                nuestros acuerdos.
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Policy;
