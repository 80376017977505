import instance from '../../lib/axios';

export const USUARIOS_ENDPOINT = '/usuarios';

export const getAllUsuarios = async (params) => {    
    const response = await instance.get(USUARIOS_ENDPOINT, { params });
    return response;
};

export const getUsuarioById = async (id) => {
    const response = await instance.get(`${USUARIOS_ENDPOINT}/${id}`);
    return response;
};

export const createUsuario = async (params) => {
    const response = await instance.post(USUARIOS_ENDPOINT, params);
    return response;
};

export const updateUsuario = async (params) => {
    const response = await instance.put(USUARIOS_ENDPOINT, params);
    return response;
};

export const updateStatusUsuario = async (params) => {
    const response = await instance.put(USUARIOS_ENDPOINT + '/status', params);
    return response;
};


export const deleteUsuario = async (id) => {
    const response = await instance.delete(`${USUARIOS_ENDPOINT}/${id}`);
    return response;
};