import { useEffect } from 'react';
import { Modal, Form, Input, Row, Button, Col, message } from 'antd';
import useSWRImmutable from 'swr/immutable';
import { USUARIOS_ENDPOINT, getUsuarioById, updateUsuario } from '../../services/api/usuarios';
import { GENERIC_ERROR_MESSAGE, GENERIC_SUCCESS_MESSAGE } from '../../constants/feedback';
import moment from 'moment';

const UsuarioItemPage = (props) => {
    const { id, isModalOpen, onOk, onCancel } = props;    
    const [form] = Form.useForm();

    const { data: usuarioData } = useSWRImmutable(
        id > 0 ? [USUARIOS_ENDPOINT, id] : null,
        ([_, id]) => getUsuarioById(id),
        {
            revalidateOnMount: true,
        }
    );

    const formatDate = (dateString) => {
        return moment(dateString).format('DD-MM-YYYY');
    };

    useEffect(() => {
        if (usuarioData) {
            const values = {
                username: usuarioData.data.username,
                nombre: usuarioData.data.nombre,
                apellidopaterno: usuarioData.data.apellidoPaterno,
                apellidomaterno: usuarioData.data.apellidoMaterno,
                fechaNacimiento: formatDate(usuarioData.data.fechaNacimiento),
                idTipoGenero: usuarioData.data.idTipoGenero === 1 ? 'Masculino' : 'Femenino',
                telefono: usuarioData.data.telefono,
                administrador: usuarioData.data.administrador,
                /*activo: usuarioData.data.activo === 1 ? "activo" : "inactivo", */
            };

            form.setFieldsValue(values);
        }
    }, [usuarioData, form]);

    const handleOk = () => {
        form.submit();
    };

    const handleCancel = () => {
        form.resetFields();
        onCancel();
    };

    return ( 
        <Modal width="45%" title='Usuario' open={isModalOpen} onOk={handleOk} onCancel={handleCancel} 
        footer={[
            <Button key="back" onClick={handleCancel}>Cancelar</Button>,
            <Button style={{ display: 'none' }} key="submit" type="primary" onClick={handleCancel}>Guardar</Button>,
            ]}
        >        
            <Form form={form} layout='vertical'>
                <Row gutter={16}>
                    <Col span={8} xs={24} sm={12} md={8}>
                        <Form.Item name='username' label='Username (Correo)'>
                            <Input readOnly disabled />
                        </Form.Item>
                    </Col>
                    <Col span={8} xs={24} sm={12} md={8}>
                        <Form.Item name='nombre' label='Nombre'>
                            <Input readOnly disabled />
                        </Form.Item>
                    </Col>
                    <Col span={8} xs={24} sm={12} md={8}>
                        <Form.Item name='apellidoPaterno' label='Apellido Paterno'>
                            <Input readOnly disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8} xs={24} sm={12} md={8}>
                        <Form.Item name='apellidoMaterno' label='Apellido Materno'>
                            <Input readOnly disabled />
                        </Form.Item>
                    </Col>
                    <Col span={8} xs={24} sm={12} md={8}>
                        <Form.Item name='fechaNacimiento' label='Fecha Nacimiento'>
                            <Input readOnly disabled />
                        </Form.Item>
                    </Col>
                    <Col span={8} xs={24} sm={12} md={8}>
                        <Form.Item name='idTipoGenero' label='Género'>
                            <Input readOnly disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8} xs={24} sm={12} md={8}>
                        <Form.Item name='telefono' label='Teléfono'>
                            <Input readOnly />
                        </Form.Item>
                    </Col>
                    <Col span={8} xs={24} sm={12} md={8}>
                
                    </Col>
                    <Col span={8} xs={24} sm={12} md={8}></Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default UsuarioItemPage;
