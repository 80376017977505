import './App.css';
import SiteLayout from './layouts/site-layout';
import { Route, Routes } from 'react-router-dom';
import AuthGuard from './pages/login/auth-guard';
import AuthPage from './pages/login/auth';
import HomePage from './pages/home';
import UsuariosPage from './pages/usuarios';
import UsuarioItemPage from './pages/usuarios/usuario-item';
import AdminNotificacionPage from './pages/admin-notificaciones';
import TermsPage from './pages/terms';
import PolicyPage from './pages/policy';

function App() {
    return (
        <Routes>
            <Route element={<SiteLayout />}>
                <Route element={<AuthGuard />}>
                    <Route path='/' element={<HomePage />} />
                    <Route path='/usuarios'>
                        <Route index element={<UsuariosPage />} />
                        <Route path=':id'>
                            <Route index element={<UsuarioItemPage />} />
                        </Route>
                    </Route>
                    <Route path='/admin-notificaciones'>
                        <Route index element={<AdminNotificacionPage />} />
                    </Route>
                </Route>
            </Route>
            <Route path='/login' element={<AuthPage />} />
            <Route path='/terms' element={<TermsPage />} />
            <Route path='/policy' element={<PolicyPage />} />
        </Routes>
    );
}
export default App;
