import instance, { loginAxios } from '../../lib/axios';

export const EVENTOS_ENDPOINT = '/eventos';
export const USUARIOS_ENDPOINT = '/usuarios';

export const getEventosCountPrevMonth = async (params) => {        
    const response = await instance.get(EVENTOS_ENDPOINT+'/previous-month-count', { params });
    return response;
};

export const getEventosCountMonth = async (params) => {        
    const response = await instance.get(EVENTOS_ENDPOINT+'/current-month-count', { params });
    return response;
};

export const getUsuarioRegisteredCount = async (id) => {
    const response = await instance.get(USUARIOS_ENDPOINT+'/registered-count');
    return response;
};
