import React, { useState, useEffect } from 'react';
import { UserOutlined, MoreOutlined, ExportOutlined } from '@ant-design/icons';
import { Avatar,  Dropdown, Layout, Menu } from 'antd';
import Icon from '@ant-design/icons';
import logoM from '../../assets/image/logo_m.png';
import ImageDashboard from '../../assets/image/img-dashboard.png';
import { Link, Outlet } from 'react-router-dom';
import { useAuth } from '../../hooks/use-auth';
import { MenuOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const { Header, Sider, Content } = Layout;

const getItem = (label, key, icon, children, type) => {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
};

const NavbarToggler = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleNavbar = () => {
        setIsExpanded(!isExpanded);
        // Aquí puedes agregar lógica adicional para manejar el colapso del navbar
    };
    return (
        <Button
            className='navbar-toggler'
            type='button'
            aria-controls='navbarsExample09'
            aria-expanded={isExpanded}
            aria-label='Toggle navigation'
            onClick={toggleNavbar}
            icon={<MenuOutlined />}
        />
    );
};

const avatarMenu = (logout) => {
    const avatarItems = [getItem('Cerrar sesión', 'sub-cerrar-sesion', <ExportOutlined />)];

    const onClick = (e) => {
        if (e.key === 'sub-cerrar-sesion') {
            logout();
        }
    };

    return <Menu items={avatarItems} onClick={onClick} />;
};


const SiteLayout = () => {
    const [collapsed, setCollapsed] = useState(false);
    const { user, logout } = useAuth();
    useEffect(() => {
        document.title = "Back-office METETE";
      }, []);
  
    return (
        <div
            className='col-lg-12 position-absolute w-100 bg-repeat-0 bg-size-cover top-3'
            style={{ backgroundImage: `url(${ImageDashboard})` }}
        >
            <div className='container'>
                <nav className='navbar navbar-expand-lg  b-radius mt-2'>
                    <div className='container-fluid'>
                        <a className='navbar-brand' href='#'>
                            <img src={logoM} alt='Logo' />{' '}
                        </a>
                        <NavbarToggler />
                        <div className='collapse navbar-collapse' id='navbarsExample09'>
                            <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
                                <li className='nav-item border-end'>
                                    <Link to='/' className='nav-link'>
                                        Inicio{' '}
                                    </Link>
                                </li>

                                <li className='nav-item border-end'>
                                    <Link to='/usuarios' className='nav-link'>
                                        Administración de usuario
                                    </Link>
                                </li>

                                <li className='nav-item'>
                                    <Link to='/admin-notificaciones' className='nav-link'>
                                        Administración de notificaciones
                                    </Link>
                                </li>
                            </ul>
                            <div className='avatar-menu'>
                            <ul className='d-flex'>
                            <li>
                                <Dropdown menu={avatarMenu(logout)} trigger={['click']} arrow>
                                    <MoreOutlined style={{ fontSize: '30px' }} />
                                </Dropdown>
                            </li>
                            <li>
                                <Avatar size={40} icon={<UserOutlined />} />
                            </li>
                            <li className='ms-2'>
                                <span>
                                    {user?.nombre} {user?.apellidoPaterno}
                                </span>
                                </li>
                            </ul>
                        </div>

                            <div className='nav-item pe-4'>
                                <a className='nav-link' onClick={logout}>
                                    Salir
                                </a>
                            </div>
                        </div>
                    </div>
                </nav>
                <Content>
                    <div className='layout-content'>
                        <Outlet />
                    </div>
                </Content>
            </div>
        </div>
    );
};

export default SiteLayout;
