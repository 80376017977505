import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import useSWRImmutable from 'swr/immutable';
import { USUARIOS_ENDPOINT, getUsuarioById, updateStatusUsuario } from '../../services/api/usuarios';


const UsuarioChangeStatus = (props) => {
    const { id, isModalOpen, onOk, onCancel } = props;
    const [nombreCompleto, setNombreCompleto] = useState('');
    const [nombreestadoUsuario, setEstadoUsuario] = useState('');
     
    const { data: usuarioData } = useSWRImmutable(
        id > 0 ? [USUARIOS_ENDPOINT, id] : null,
        ([_, id]) => getUsuarioById(id),
        {
            revalidateOnMount: true,
        }
    );
          
    useEffect(() => {
        if (usuarioData) {                            
            const nombre = usuarioData.data.nombre;
            const apellidopaterno = usuarioData.data.apellidoPaterno;
            const apellidomaterno = usuarioData.data.apellidoMaterno;            
            const estadoUsuario = usuarioData.data.activo;
            const nombreEstado = estadoUsuario ? 'Inactivo' : 'Activo';
            setNombreCompleto(`${nombre} ${apellidopaterno} ${apellidomaterno}`);
            setEstadoUsuario(`${nombreEstado}`);
        }
    }, [usuarioData]);


    const handleOk = () => {
       
    };
    const handleCancel = () => {
        onCancel();
    };
    const handleFinishClick = async (values) => {
        let params;
        if (usuarioData) {
            params = {
                id: usuarioData.data.id,
                activo: !usuarioData.data.activo,
            }
        };
        if (id > 0 && params) {
            await updateStatusUsuario(params);
        }
        onOk();        
    };

    return ( 
        <>        
        <Modal
          title="Confirmar Cambio de Estado"
          open={isModalOpen} onOk={handleFinishClick} onCancel={handleCancel} 
          okText="Confirmar"
          cancelText="Cancelar"
        >
          <p>¿Estás seguro de que deseas cambiar al usuario <strong>{nombreCompleto}</strong> al estado <strong>{nombreestadoUsuario}</strong>?</p>
        </Modal>
      </>
    );
};

export default UsuarioChangeStatus;
