import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import esES from 'antd/es/locale/es_ES';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import { AuthProvider } from './contexts/auth-context';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <BrowserRouter>
            <AuthProvider>
                <ConfigProvider locale={esES}>
                    <App />
                </ConfigProvider>            
            </AuthProvider>
      </BrowserRouter>
    </React.StrictMode>
);
reportWebVitals();
