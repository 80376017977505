import React from 'react';
import logoMeteteSvg from '../../assets/image/Logo_metete.svg';
import imgDashboardPng from '../../assets/image/img-dashboard.png';

const Terms = () => {
    return (
        <>
            <div
                className='col-lg-12 position-absolute  w-100   bg-repeat-0 bg-size-cover top-3'
                style={{ backgroundImage: `url(${imgDashboardPng})` }}
            >
                <div className='container'>
                    <div className='row bg-box rounded-4 my-3 mx-2 p-3 mb-5'>
                        <div className='card'>
                            <h4 className='card-header text-center text-color-blue py-3'>
                                Términos y Condiciones de Uso de la Aplicación
                            </h4>

                            <div className='col-md-4 mx-auto text-center py-4'>
                                <img className='img-fluid w-50' src={logoMeteteSvg} alt='Metete' />
                            </div>

                            <div className='card-body'>
                                <h5 className='card-title text-center'>
                                    Estos términos y condiciones regulan el uso de la aplicación móvil Metete, propiedad
                                    de MeteteApp, por parte de los usuarios.
                                </h5>

                                <div className='card-text'>
                                    <ul className='list-group list-group-flush'>
                                        <li className='list-group-item'>
                                            {' '}
                                            <strong>1. Aceptación de los Términos</strong>{' '}
                                        </li>

                                        <li className='list-group-item'>
                                            <p>
                                                Al descargar, acceder o utilizar la aplicación, aceptas estos términos y
                                                condiciones en su totalidad. Si no estás de acuerdo con alguna parte de
                                                estos términos, abstente de utilizar la aplicación.
                                            </p>
                                        </li>

                                        <li className='list-group-item'>
                                            {' '}
                                            <strong>2. Uso de la Aplicación</strong>{' '}
                                        </li>
                                        <li className='list-group-item'>
                                            <p>
                                                La aplicación está diseñada para conectar a entusiastas del deporte y la
                                                actividad física, facilitando la organización y participación en eventos
                                                deportivos locales. Ofrece a los usuarios la posibilidad de descubrir,
                                                unirse y crear eventos deportivos, promoviendo la interacción entre
                                                personas con intereses comunes y fomentando un estilo de vida activo y
                                                saludable.
                                                <br />
                                                Te comprometes a utilizar la aplicación de manera responsable y conforme
                                                a la ley, en caso contrario, MeteteApp puede desvicular tú cuenta.
                                                <br />
                                                No debes utilizar la aplicación para actividades ilegales, inapropiadas
                                                o que violen los derechos de terceros.
                                            </p>
                                        </li>

                                        <li className='list-group-item'>
                                            {' '}
                                            <strong>3. Registro y Cuentas de Usuario</strong>{' '}
                                        </li>
                                        <li className='list-group-item'>
                                            <p>
                                                Para acceder a ciertas funciones de la aplicación, puede que se requiera
                                                el registro de una cuenta. La información proporcionada al crear una
                                                cuenta debe ser precisa y completa. Eres responsable de mantener la
                                                confidencialidad de tu cuenta.
                                            </p>
                                        </li>

                                        <li className='list-group-item'>
                                            {' '}
                                            <strong>4. Propiedad Intelectual</strong>{' '}
                                        </li>
                                        <li className='list-group-item'>
                                            <p>
                                                Todos los derechos de propiedad intelectual de la aplicación y su
                                                contenido pertenecen a Metete y su equipo. No tienes permiso para
                                                copiar, modificar, distribuir o utilizar el contenido de la aplicación
                                                sin autorización.
                                            </p>
                                        </li>

                                        <li className='list-group-item'>
                                            {' '}
                                            <strong>5. Privacidad</strong>{' '}
                                        </li>
                                        <li className='list-group-item'>
                                            <p>
                                                La privacidad del usuario es importante. Lee nuestra Política de
                                                Privacidad para comprender cómo recopilamos, utilizamos y protegemos tus
                                                datos personales.
                                            </p>
                                        </li>

                                        <li className='list-group-item'>
                                            {' '}
                                            <strong>6. Limitaciones de Responsabilidad</strong>{' '}
                                        </li>
                                        <li className='list-group-item'>
                                            <p>
                                                {' '}
                                                La aplicación se proporciona "tal cual" y "según disponibilidad". No nos
                                                responsabilizamos por cualquier daño o pérdida causada por el uso de la
                                                aplicación.
                                                <br />
                                                No garantizamos que la aplicación sea continua, segura, libre de errores
                                                o esté libre de virus u otros componentes dañinos.
                                            </p>
                                        </li>

                                        <li className='list-group-item'>
                                            {' '}
                                            <strong>7. Modificaciones y Actualizaciones</strong>{' '}
                                        </li>
                                        <li className='list-group-item'>
                                            <p>
                                                {' '}
                                                Nos reservamos el derecho de modificar, actualizar o suspender la
                                                aplicación en cualquier momento sin previo aviso.
                                            </p>
                                        </li>

                                        <li className='list-group-item'>
                                            {' '}
                                            <strong>8. Terminación</strong>{' '}
                                        </li>
                                        <li className='list-group-item'>
                                            <p>
                                                {' '}
                                                Podemos suspender o terminar tu acceso a la aplicación si se incumplen
                                                estos términos y condiciones.
                                            </p>
                                        </li>

                                        <li className='list-group-item'>
                                            {' '}
                                            <strong>9. Ley Aplicable</strong>{' '}
                                        </li>
                                        <li className='list-group-item'>
                                            <p> Estos términos y condiciones se rigen por las leyes de Chile.</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Terms;
