import { Button, Form, Input, message } from 'antd';
import { useState } from 'react';
import imgMetete from '../../assets/image/img-metete.png';
import logoMetete from '../../assets/image/Logo_metete.svg';
import { GENERIC_ERROR_MESSAGE, validateMessages } from '../../constants/feedback';
import { useAuth } from '../../hooks/use-auth';
import { signIn } from '../../services/api/auth';

const itemLayout = {
    labelCol: { span: 24 },
};

const AuthPage = () => {
  const [isLoading, setLoading] = useState(false);
  const { login } = useAuth();

  const handleFinish = async (values) => {
      try {
          setLoading(true);                                                
          const response = await signIn(values);    
          login(response.data);
      } catch (error) {
          // Revisa primero si existe una respuesta HTTP
          if (error.response) {
              if (error.response.status === 401) {
                  message.error(error.response.data.Message);                  
              } else {
                  message.error(GENERIC_ERROR_MESSAGE);              
              }
          } else {
              // Maneja otros errores (como problemas de red o la API no disponible)
              message.error("Error al conectarse con el servicio. Por favor, inténtelo de nuevo más tarde.");
              console.log('Error de conexión o servicio no disponible');
          }
      } finally {
          setLoading(false);
      }
  };



    return (
    <div className="bg-login">
    <section className="position-relative h-100 pb-4 h-box">
      <div className="container d-flex flex-wrap justify-content-center justify-content-xl-start h-100">
        <div className="w-100 align-self-end  pt-md-4" style={{ maxWidth: 526 }}>
          <div className="text-center pb-4">            
            <img className="img-fluid" style={{ height: 150 }} src={logoMetete} alt="Logo" />            
          </div>
          <div className="border-box rounded-4 bg-white shadow p-5">
           <div className="text-center pb-2"><h4>Inicio de sesión</h4></div>
            <Form  onFinish={handleFinish} validateMessages={validateMessages} autoComplete='off' className="needs-validation mb-3">            
            <div className="position-relative mb-4">            
              <Form.Item  name="username" label='Usuario' {...itemLayout} rules={[{ required: true, whitespace:true, message: 'Por favor ingresa tu usuario!' }]}>                
                <Input maxLength={45} allowClear />
              </Form.Item>
            </div>
            <div className="mb-4">              
              <Form.Item name='password' label='Contraseña' {...itemLayout} rules={[{ required: true, whitespace: true, message: 'Por favor ingresa tu contraseña!' }]}>
                <div className="password-toggle">
                  <Input.Password maxLength={100} allowClear />
                </div>
              </Form.Item>              
            </div>            
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={isLoading}  className="btn btn-login shadow-primary btn-lg w-100 rounded-pill text-uppercase">
                  Iniciar sesión
                </Button>
              </Form.Item>
            </Form>        
          </div>
        </div>
        <div className="position-absolute top-0 end-0 w-50 h-100 d-xl-block d-none" style={{ backgroundImage: `url(${imgMetete})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
        </div>
      </div>
    </section>
    </div>
    );
};

export default AuthPage;
