import IcoCuestionarioSvg from '../../assets/image/ico-cuestionario.svg';
import IcoUsuario from '../../assets/image/ico-usuario.png';
import IcoEvento from '../../assets/image/ico-evento.png';
import IcoEventoMes from '../../assets/image/ico-evento-mes.png';
import Banner from '../../assets/image/banner.png';
import { getEventosCountPrevMonth, getEventosCountMonth, getUsuarioRegisteredCount } from '../../services/api/escritorio';
import { useState, useEffect } from 'react';

const HomeDesktop = () => {    
    const [EventosCountPrevMonth, setEventosCountPrevMonth] = useState(0);
    const [EventosCountMonth, setEventosCountMonth] = useState(0);
    const [UsuarioRegisteredCount, setUsuarioRegisteredCount] = useState(0);
    const date = new Date();
    date.setMonth(date.getMonth() - 1); 
    const monthPrevName = date.toLocaleDateString('es-ES', { month: 'long' });
        
    useEffect(() => {
      const fetchEventosCountPrevMonth= async () => {
          try {
              const response = await getEventosCountPrevMonth();
              if(response && response.data) {
                setEventosCountPrevMonth(response.data);
              }
          } catch (error) {
              console.error('Error al obtener la cantidad de eventos del mes anterior', error);
          }
      };

      fetchEventosCountPrevMonth();      
  }, []);

  useEffect(() => {
    const fetchEventosCountMonth = async () => {
        try {
            const response = await getEventosCountMonth();
            if(response && response.data) {
              setEventosCountMonth(response.data);
            }
        } catch (error) {
            console.error('Error al obtener la cantidad de eventos del mes en curso', error);
        }
    };

    fetchEventosCountMonth();      
  }, []);

  useEffect(() => {
    const fetchUsuarioRegisteredCount = async () => {
        try {
            const response = await getUsuarioRegisteredCount();
            if(response && response.data) {
              setUsuarioRegisteredCount(response.data);
            }
        } catch (error) {
            console.error('Error al obtener la cantidad de usuarios', error);
        }
    };

    fetchUsuarioRegisteredCount();      
  }, []);

  return (
        <div>     
    <div className="row my-3">
      <div className=" col-md-12">
        <div className="text-center"><img className="banner-custom-size img-fluid " src={Banner} alt="Banner Home"/> </div>
      </div>
    </div>
    
    <div className="row bg-box rounded-4 my-1 mx-4 py-3 text-white">

      <div className="col-md-4 text-center border-end py-4">
        <h6 className=" fw-semibold">Cantidad de usuario</h6>
        <img className="mx-2" src={IcoUsuario}/>
        <p><span className="fw-semibold">Título:</span>  Usuario</p>
        <div className="border-box box-circle mx-auto rounded-circle d-flex align-items-center justify-content-center">
          <div>
          <p className="fs-1 fw-semibold pt-3">{UsuarioRegisteredCount}</p>
          </div>
        </div>
      </div>

      <div className="col-md-4 text-center border-end py-4">
        <h6 className="fw-semibold">Cantidad de eventos en cursos</h6>
        <img className="mx-2" src={IcoEvento}/>
        <p><span className="fw-semibold">Título:</span>  Eventos</p>
        <div className="border-box box-circle mx-auto rounded-circle d-flex align-items-center justify-content-center">
          <div>
          <p className="fs-1 fw-semibold pt-3">{EventosCountMonth}</p>
          </div>
        </div>
      </div>

      <div className="col-md-4 text-center py-2">
        <h6 className="fw-semibold">Cantidad de evetos del mes pasado</h6>
        <img className="mx-2" src={IcoEventoMes}/>
        <p><span className="fw-semibold">Título:</span>  Eventos de {monthPrevName} </p>
        <div className="border-box box-circle mx-auto rounded-circle d-flex align-items-center justify-content-center">
          <div>
          <p className="fs-1 fw-semibold pt-3">{EventosCountPrevMonth}</p>
          </div>
        </div>

      </div>

    </div>
        </div>
    );
};

export default HomeDesktop;
